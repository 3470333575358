export const hcpIIR: any = {
    hcpMainMenu: "Main Navigational Menu HCP GCSO",
    hcpFooterMenu: "Footer Navigation Menu HCP GCSO",
    hcpCarousel: "",
    hcpBanner: "Banner CLINICAL TRIALS IN INHERITED RETINAL DISEASES",
    hcpBannerHTML: "Banner - HTML - IIR - HCP - Canada",
    hcpFooter: "Retina Global Footer HCP - Canada",
    hcpExitPopup: "Retina Canada HCP Exit Popup HTML",
    hcpHCPSwitcher: "HCP Switcher Modal Pop Up",
    hcpTopNav: "Top Navigational Menu HCP - Canada",
    hcpSiteLogo: "Site logos HCP",
    hcpFootRef: "Clinical Trials HCP - References",
    hcpNavCalloutPara: "Clinical Trials - Navigational Callout HTML - Canada",
    hcpTestOrRetestPara: "Clinical Trials - Test or retest your patients HTML - Canada",
    hcpAnOpporPara: "Clinical Trials - An opportunity to contribute to new studies HTML - Canada",
    hcpEvolutionGTPara: "Clinical Trials - Evolution of genetic testing HTML - Canada",
    redirectLangUrl: {
      "redirectUrl": {
        "en": "/hcp/investigating-irds",
        "fr": "/fr/hcp/investigating-irds"
      }
    },
    backToTopGTM: "Back to top - investigating irds",
    footerClassName: "investigating-irds-footer"
  }
