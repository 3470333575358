import React from 'react'
import { graphql } from 'gatsby'
import { HTMLRenderer, Layout, PageWrapper, HcpMobileBanner, HcpClinicalTrials } from '@retina-packages/retina-theme-bootstrap';
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer';
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes'
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders';
import { GeneTestEvolutionContainerWrapper, CalloutWithHeaderContainer, CalloutWithHeaderWrapper, HcpInvestingIrds } from './styles'
import { hcpIIR } from './constants';
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import { deriveMultiLogo, deriveSiteMenus, footRefHTMLBuilder, fullHTMLDataBuilder } from '../../../utils';
import retinaConfig from '../../../utils/retinaConfigs';
import { ContainerWrapper, HcpFooterBlockWrapper, TestorRetestPatientSectionWrapper } from '../../../css/common/style';

const GeneticTestingBenefitTemplate = (props: Props) => {
  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso

  const pgContextsIIR = props.pageContext
  const htmlStaticFilesIIR = {
    nonSVGImages: pgContextsIIR.nonSVGImages,
    svgMediaImages: pgContextsIIR.svgMediaImages,
    allMediaDocument: pgContextsIIR.documents
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: hcpIIR.hcpMainMenu, siteFooterMenu: hcpIIR.hcpHomeFooterMenu, addHCPPrefix: retinaConfig.addHcpPrefix });
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name

  const bannerTitle = hcpIIR.hcpBanner
  const carouselTitle = hcpIIR.hcpCarousel
  const footerTitle = hcpIIR.hcpFooter
  const exitPopupTitle = hcpIIR.hcpExitPopup
  const hcpSwitcherTitle = hcpIIR.hcpHCPSwitcher
  const topNavTitle = hcpIIR.hcpTopNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle})

  const bannerHTML = fullHTMLDataBuilder({ blocks, title: hcpIIR.hcpBannerHTML })
  const hcpClinicalTrialReference = footRefHTMLBuilder({ blocks, title: hcpIIR.hcpFootRef })
  const siteLogos: any = deriveMultiLogo({ blocks, title: hcpIIR.hcpSiteLogo })
  const opItems = fullHTMLDataBuilder({ blocks, title: hcpIIR.hcpAnOpporPara });
  const navCalloutData = fullHTMLDataBuilder({ blocks, title: hcpIIR.hcpNavCalloutPara });
  const testOrRetestYourPatientsSectionData = fullHTMLDataBuilder({ blocks, title: hcpIIR.hcpTestOrRetestPara });
  const evTestData = fullHTMLDataBuilder({ blocks, title: hcpIIR.hcpEvolutionGTPara });

  return (
    <HcpInvestingIrds>
    <HcpClinicalTrials>
      <HcpMobileBanner className="hcpmobilewrapper">
        {props.pageContext !== null && props.pageContext.metaInfo !== null && (
          <MetaTagContainer metaData={props.pageContext.metaInfo} />
        )}
        <Layout
          title={"siteTitle"}
          location={props.location}
          modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
          data={mainMenu}
          mdata={footerMenu}
          footerData={footerText}
          audience={audience}
          exitPopData={exitData}
          footref={hcpClinicalTrialReference}
          hcplinks={hcpSwitcher}
          topNavigation={topNavs}
          siteLogos={siteLogos}
          gtmBackToTopLabel={hcpIIR.backToTopGTM}
          staticMediaFiles={htmlStaticFilesIIR}
          showRefContent={retinaConfig.showRefContent}
          hideRefContent={retinaConfig.hideRefContent}
          hcpValidate={retinaConfig.hcpValidate}
          preIndexUrl = {retinaConfig.preIndexUrl}
          hcpHomeUrl={retinaConfig.hcpHomeUrl}
          hcpPrefix={retinaConfig.hcpPrefix}
          siteHomeUrl={retinaConfig.siteHomeUrl}
          footerClassName={hcpIIR.footerClassName}
          languageConfig={{... retinaConfig.langConfig, ...hcpIIR.redirectLangUrl}}
          {... {...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
        >
          <PageWrapper className='pagewrapper pagewrapper-clinical-trial pagewrapper-investigating-irds'>
            {/* Banner section */}
            <MobileBanner className="mobile-banner clinical-trials-banner">
              <HTMLRenderer data={htmlStaticFilesIIR} html={bannerHTML} tagName='section' className='internal-banner o-column--full' />
            </MobileBanner>
            <CalloutWithHeaderContainer className='main-section o-top-space emerging-science-intro'>
              <CalloutWithHeaderWrapper className='o-container'>
                <HTMLRenderer html={opItems} data={htmlStaticFilesIIR} tagName={`div`} className={`o-text-center`} />
              </CalloutWithHeaderWrapper>
              {evTestData && (
                <GeneTestEvolutionContainerWrapper className='c-family--story evolution-genetic-test'>
                  <HTMLRenderer html={evTestData} data={htmlStaticFilesIIR} tagName={`div`} className={`o-container`} />
                </GeneTestEvolutionContainerWrapper>
              )}
               <ContainerWrapper className='containercliniwrap'>
                  <TestorRetestPatientSectionWrapper>
                    <HTMLRenderer html={testOrRetestYourPatientsSectionData} data={htmlStaticFilesIIR} tagName={`div`} className={`o-container`} />
                  </TestorRetestPatientSectionWrapper>
                  <HcpFooterBlockWrapper className='o-container hcp-footer-wrap arrow-footer'>
                    <HTMLRenderer html={navCalloutData} data={htmlStaticFilesIIR} tagName={`div`} className={`footer-block o-top-space emerging-science--footer`} />
                  </HcpFooterBlockWrapper>
               </ContainerWrapper>
            </CalloutWithHeaderContainer>
          </PageWrapper>
        </Layout>
      </HcpMobileBanner>
    </HcpClinicalTrials>
    </HcpInvestingIrds>
  )
}

export default GeneticTestingBenefitTemplate

export const pageQuery = graphql`
  query($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ...HcpIIRPageQuery
      }
    }
  }
`
