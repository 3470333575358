import styled from 'styled-components'
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device'

export const GeneTestEvolutionContainerWrapper = styled.div`
	margin: 10rem 0 5rem;
	position: relative;

	@media ${device.laptopMedium} {
		margin: 2rem 0 1rem;
		margin-bottom: 48px !important;
	}

	&:before {
    content: "";
    background: #00FFD9;
    display: block;
    width: 100%;
    height: 70%;
    position: absolute;
    top: 15%;

		@media ${device.laptopMedium} {
			top: 5%;
			height: 92%;
		}
	}

	.o-container {
		.c-family--story__inner {
			background-color: #f7f7f7;
			box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
			position: relative;
			padding: 10rem 9rem;
			color: #000000;

			@media ${device.laptopMedium} {
				padding: 2rem 3rem;
				font-size: 2rem;
    		line-height: 2.6rem;
				margin-top: 48px;
			}

			&:before {
				content: "";
				background: linear-gradient(90deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
				width: 100%;
				height: 10px;
				position: absolute;
				top: 0;
				left: 0;
				height: 10px;
			}

			.o-header--h2 {
				font-size: 4.2rem;
				font-weight: 600;
				line-height: 5rem;
				color: #000000;
				margin: 0 0 40px;
        font-family: "Gotham", Arial, Helvetica, sans-serif;
				text-align: center;

				@media ${device.laptopMedium} {
					margin: 20px 0 0 0;
					text-align: center;
					font-size: 2.5rem;
					line-height: 3rem;
				}
				p {
					font-size: 4.2rem;
					font-weight: 600;
					line-height: 5rem;
					color: #000000;
					margin: 0 0 40px;
          font-family: "Gotham", Arial, Helvetica, sans-serif;
					text-align: center;

					@media ${device.laptopMedium} {
						margin: 20px 0 0 0;
						text-align: center;
						font-size: 2.5rem;
    				line-height: 3rem;
					}
				}
			}

			.genetic-evolution {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				position: relative;

				@media ${device.laptopMedium} {
					margin-top: 32px;
          display: inline;
				}
        p {
          margin-bottom: 0;
        }
				.evo-genetic-container {
					background-color: #000000;
    			margin-bottom: 50px;

          @media ${device.mobileS} and ${device.laptopMedium}{
          margin-top: 32px;
          }
            .o-header--h3 {
                color: #00FFD9;
                font-size: 2.5rem;
                line-height: 3rem;
                margin: 20px 0px 0px;
                padding: 2rem 1rem 0px;
                width: 100%;
                font-family: "Gotham", Arial, Helvetica, sans-serif;
                text-align: center;
                font-weight: 600;

                @media ${device.mobileS} and ${device.laptopMedium} {
                  padding: 4rem 1rem 1px;
                }
                @media ${device.ipadLandscapemin}{
                  font-size: 3.4rem;
                  line-height: 4.7rem;
                  text-align: center;
                  margin-top: 60px;
                  padding: 0 150px;
                  font-weight: 600;
                  font-family: "Gotham", Arial, Helvetica, sans-serif;
                }

              p {
                font-size: 2.5rem;
                line-height: 3rem;
                margin: 20px 0 0 0;
                margin-top: 60px;
                padding: 0 150px;
                background-color: #000000;
                width: 100%;
                color: #00FFD9;
                font-weight: 600;
                font-family: "Gotham", Arial, Helvetica, sans-serif;

                @media ${device.ipadLandscapemin} {
                  font-size: 3.4rem;
                  line-height: 4.7rem;
                  text-align: center;
                }
              }
            }
          .desktop-image {
            display: block;

            @media ${device.laptopPros} {
              display: none;
            }
            @media ${device.tablet}{
              margin-top: 22px;
            }
            @media ${device.ipadLandscapemin} {
              margin-top: 10px;
            }
          }
					img {
						@media ${device.laptopMedium} {
							width: 100%;

							margin-bottom: 32px;
						}

						.desktop-image {
							display: block;

							@media ${device.laptopPros} {
								display: none;
							}
						}

						.mobi-image {
							display: none;

							@media ${device.laptopPros} {
								display: block;
							}
						}
					}

					.line-graph-copy {
						margin: 8px 0 40px;
            font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
						font-size: 18px;
						line-height: 22px;
						text-align: center;
						color: #fff;
						padding: 0 65px 80px;

            @media ${device.mobileS} and ${device.laptopPros}{
              font-size: 16px !important;
						  line-height: 20px !important;
              margin: 32px 0;
							padding: 0 3rem 24px;
            }
            @media ${device.tablet} and ${device.laptopMedium}{
              padding: 0 65px 104px;
            }

            @media ${device.tablet} and ${device.laptopMedium} {
							margin: 24px 0;
						}
            @media ${device.desktopmenu}{
              font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
              font-size: 18px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.22;
              letter-spacing: normal;
              text-align: center;
              display: block;
              height: auto;
            }
            @media ${device.desktopsignup}{
              font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
              font-size: 18px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.22;
              letter-spacing: normal;
              text-align: center;
              display: block;
              height: auto;

            }
						a {
							color: #00FFD9;
							cursor: pointer;
						}
						p {
							margin: 8px 0 40px;
              font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
							font-size: 18px;
							line-height: 22px;
							text-align: center;
							color: #fff;
							padding: 0 65px 80px;

							@media ${device.laptopMedium} {
								margin: 24px 0;
							}

							@media ${device.laptopPros} {
								font-size: 16px;
    						line-height: 20px;
							}

							@media ${device.mobileMedium} {
								padding: 0;
							}
              &: first-child{
                text-align: center;
              }
							a {
								color: #00FFD9;
								cursor: pointer;
							}
						}
					}

				}

				.o-paragraph {
					width: 80%;
					margin: auto;
          font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;
					color: #000000;
          display: block;
					@media ${device.mobileMedium} {
						width: 100%;
						padding: 0 1rem;
						font-size: 1.8rem;
						line-height: 2.6rem;
					}

					b {
						color: #8000BE;
						font-weight: 500 !important;
					}

					strong {
						font-weight: 500;
						color: #8000BE;
					}
					p {
						width: 80%;
						margin: auto;
            font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;
						color: #000000;

						@media ${device.mobileMedium} {
							width: 100%;
							padding: 0 1rem;
							font-size: 1.8rem;
							line-height: 2.6rem;
						}

						b {
							color: #8000BE;
							font-weight: 500 !important;
						}
						strong {
							font-weight: 500;
							color: #8000BE;
						}
					}
				}
			}
		}
	}
`;

export const HcpInvestingIrds = styled.div`
.clinical-trials-banner{
  height: auto;
  .internal-banner{
    .gatsby-image-wrapper-constrained{
      width:100%;
      height: 100%;
      @media ${device.mobileMedium}{
        height: 310px !important;
      }
      @media ${device.laptopMedium}{
        height: 470px;
        object-position: 90% 0%;
      }
      @media ${device.desktopsignup}{
        width: 100%;
        margin-top: -2px;
      }
      @media ${device.largeDesktop}{
        height: 380px;
      }
      @media ${device.extraLargeDesktop}{
        height: 460px;
      }
    }
    .internal-banner__contant{
      .o-container{
          padding: 0 3rem;
          @media ${device.tablet}{
            padding: 0 5rem;
          }
        @media ${device.desktopStart}{
          padding: 0 2rem;
        }
        h1{
          @media ${device.mobileMedium}{
            margin-top:0;
          }
          @media ${device.ipadLandscapemin} and ${device.desktopmenu} {
            margin-top: 15px;
            max-width: 100% !important;
          }
          @media ${device.desktopsignup} {
            max-width: 75%;
          }
        }
      }
    }
    .internal-banner__image{
      @media ${device.mobileMedium}{
        height: 310px;
      }
      @media ${device.mobileStart} and ${device.laptopMedium}{
        height: 470px;
      }
      .desktop-image{
        height: 100%;
          .media--image{
            height: 100%;
          }
        img{
          display: block;
          object-fit: cover;
          width: 100%;
          height: 100%;
          object-position: 90% 0%;

          @media ${device.laptopPros}{
            display: none;
          }
          @media ${device.ipadLandscapemin} {
            width: 100%;
          }
          @media ${device.desktopsignup} and ${device.midDesktopMax} {
            margin: -2px 0;
          }
        }
      }
      .mobile-image{
        .media--image{
          height: 100%;
        }
        img{
          display: none;
          @media ${device.laptopPros}{
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
            object-position: center !important;
          }
        }
      }
    }
  }
}

.desktop-image{
  display: block;
  @media ${device.laptopPros}{
    display: none;
  }
}

.mobile-graph-image{
  display: none;
  @media ${device.laptopPros}{
    display: block;
    margin: 2.25rem 1.4rem 1rem;
  }
  .gatsby-image-wrapper{
    @media ${device.laptopPros}{
      display: block;
    }
  }
}

.hcpmobilewrapper{
  &:not(.patient-genetic){
    .show{
    +.main {
        padding-top: 0px;
      }
    }
  }
}

.containercliniwrap{
  .o-container{
    .info-box-2{
      @media ${device.laptopMedium}{
        padding: 20px 10px;
      }
      @media ${device.desktopsignup}{
        padding: 40px 70px !important;
      }
    }
  }
  .hcp-footer-wrap{
    .footer-block{
      margin: 0 auto;
      .footer-block--row{
        @media ${device.ipadLandscapemin}and ${device.desktopmenu}{
          width: 100%;
        }
        .footer-inner-block{
          @media ${device.laptopMedium}{
            width: 90% !important;
          }
          @media ${device.ipadLandscapemin} and ${device.desktopmenu}{
              padding: 25px 20px 120px;
          }

          .o-common-title{
            h3{
              @media ${device.ipadLandscapemin}and ${device.desktopmenu}{
                padding: 0 50px;
              }
            }
          }
          .o-button{
            p{
              a{
                padding: 10px 26px;
                @media ${device.mobilemin}and ${device.mobileMedium}{
                  padding: 10px 10px;
                }
                @media ${device.laptopMedium}{
                  margin-top: 32px !important;
                }
                &:after{
                  @media ${device.ipadLandscapemin} and ${device.desktopmenu}{
                    width: 19px !important;
                    height: 14px !important;
                  }
                  @media ${device.desktopsignup}{
                    width: 18px !important;
                    height: 14px !important;
                  }
                  @media ${device.laptopL}{
                    width: 16px !important;
                    height: 14px !important;
                  }
                }
              }
            }
          }
          &:nth-child(2){
            .o-button{
              p{
                a{
                  &:after{
                    @media ${device.ipadLandscapemin} and ${device.desktopmenu}{
                      width: 21px !important;
                      height: 14px !important;
                    }
                    @media ${device.desktopsignup}{
                      width: 20px !important;
                      height: 14px !important;
                    }
                    @media ${device.laptopL}{
                      width: 16px !important;
                      height: 14px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// .footer{
//   @media ${device.tablet}{
//     margin: -22rem 0 0;
//   }
//   @media ${device.desktopStart}{
//     margin: -21rem 0 0;
//   }
//   .o-backtotop-btn{
//     @media ${device.laptopMedium}{
//       background-color: transparent;
//     }
//   }
//   .footercontainer{
//     margin: 0 auto;
//     padding: 0;
//     @media ${device.mobilemin} and ${device.laptopPros}{
//       margin: 100px auto 0;
//     }
//     @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
//       margin: 15px auto 0;
//     }
//     @media ${device.desktopsignup} and ${device.midDesktopMax} {
//       margin: 25px auto 0;
//     }
//   }
//   .c-hide-references{
//     ul{
//       li{
//         a{
//           color: rgb(0, 255, 217);
//           text-decoration: underline;
//         }
//       }
//     }
//   }
// }
`;
export const CalloutWithHeaderContainer = styled.div`
	padding-top: 88px;

	@media ${device.laptopMedium} {
		padding-top: 30px;
	}
`;

export const CalloutWithHeaderWrapper = styled.div`
	.o-text-center {
		text-align: center;
    @media ${device.mobileS} and ${device.laptopMedium}{
      margin-bottom: -6px;
    }
		@media ${device.laptopMedium} {
			font-size: 2rem;
			line-height: 2.6rem;
		}

		.o-header--h2 {
			font-size: 3.2rem;
			font-weight: normal;
			line-height: 5rem;
			color: #000000;
      font-family: "Gotham", Arial, Helvetica, sans-serif;
			padding: 0 0 20px;
			margin: 0;
      @media ${device.mobileS} and ${device.laptopMedium}{
        padding:0;
      }
			@media ${device.laptopMedium} {
				text-align: center;
				font-size: 2.5rem;
				line-height: 3rem;
			}
      @media ${device.ipadLandscapemin}{
        padding: 0 0 5px;
      }
			p	{
				font-size: 3.2rem;
				font-weight: normal;
				line-height: 5rem;
				color: #000000;
        font-family: "Gotham", Arial, Helvetica, sans-serif;
				padding: 0 0 20px;
				margin: 0;

				@media ${device.laptopMedium} {
					text-align: center;
					font-size: 2.5rem;
					line-height: 3rem;
				}
			}
		}

		.o-text--book {
			display: block;

			p {
				width: 80%;
				margin: auto;
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
				color: #000000;
        font-family: "Gotham-Book", Arial, Helvetica, sans-serif;

				em {
					font-style: italic;
				}
			}

			&.pb-5 {
				&:last-child {
					padding-bottom: 0 !important;
				}
			}
		}
	}
`;
